 
import '@assets/iconfont/iconfont.css'
import { throttle, getFormData, sendAjax, validEmail, isElementInViewport, hasNameOrEmail } from '@/utils/utils.js'

var isSalePage = location.pathname.indexOf('container-sales.html') > 0
// 刷新页面判断页面是否滚动,头部设置背景色
var topInit = $(document).scrollTop()
var slideHeightInit = $('.slider-box').outerHeight() || 0
if (topInit > slideHeightInit) {
    $('.navbar').addClass('blue')
}
$('.language-list').on('click', '.lang-item',function (e) {
    var that = $(this)
    var language = that.data('lang')
    var pathname = location.pathname
    if (language === 'zh') {
        console.log('BASE_API2 :>> ', BASE_API2);
        // location.href = 'https://www.ucontainers.com/'
        location.href = BASE_API2 + '/'

    } else {
        // location.href = 'https://www.ucontainers.com.cn/'
        location.reload()
        
        
    }
    // if (language === 'zh') {
        
    //     location.href = 'https://ucontainers.com/'
      
        
    // } else {
    //     location.reload()
    // }
   
})
function submitForm(path, formId) { 
    console.log('form :>> ', form);
    var form = getFormData('#form')
    if (formId) {
        form = getFormData(formId)
    }
    var empty = true //输入框是否全部为空
    for (let key in form) {
        let val = form[key]
        if (val) {
            empty = false
        }
    }

    if (empty) {
        $.message({
            message: 'Please fill in the content',
        });
        return
    }
    var hasContact = hasNameOrEmail(form)
    if (!hasContact) {
        return
    }
    var emailVal = form.email
    var phoneVal = form.mobile
    var emailAndPhone = form.emailAndMobile
    if (emailVal && !validEmail(emailVal)) {
        $.message({
            message: 'The Email format is incorrect, please re-enter',
        });
        return
    }
    // if (phoneVal && !validP(phoneVal)) {
    //     $.message({
    //     });
    //     return
    // }
    console.log('form :>> ', form);
    const data = form
    // $.ajax({
    //     url,
    //     type: 'post',
    //     dataType: 'json',
    //     contentType:'application/json',
    //     data,
    //     success(response) {
    //         console.log(response);
    //     },
    // });
  sendAjax(path, data).done(res => {
    if (res.success && res.errorCode === 200) {
            
    //   $.message({
    //       message: 'Submitted successfully',
    //       type: 'success',
    //   });
        $(formId)[0].reset()
        location.href = './file-read.html'
        
    if (isSalePage) {
        $('.cl-introduction.new #country').val(null).trigger("change");
        $('.cl-introduction.new #address').val(null).trigger("change");
    }

    } else if (res.errorCode === 500) {
        $.message({
            message: res.message,
            type: 'error',
        });
    }

  }).fail(err => {
        console.log('err :>> ', err);
        $.message({
            message: err.error,
            type: 'error',
        });
    })

    
}
// 提交表单 节流
const throttleHandle = throttle(submitForm, 2000)

export function throttleAjax(path) {
    
    $('#ipt,#ipt_old, #iptFoot').on('click', function (e) {
        console.log('这里')
        var formDom = '#form'
        var sale_new = $('#form_sale_new').length > 0 && $('#form_sale_new').css('display') !== 'none'
        var sale_old = $('#form_sale_old').length > 0 && $('#form_sale_old').css('display') !== 'none'
        if (sale_new) {
            formDom = '#form_sale_new'
        }
        if (sale_old) {
            formDom = '#form_sale_old'
        }
    throttleHandle(path,formDom)
})
}

// $('.hot-box').on('mouseenter mouseleave', '.item-content', function (e) {
//   var that = $(this)
//   var textDom = that.find('.item-content-text')
//   var height = textDom.outerHeight()

//   if (e.type === 'mouseenter') {
    
//     textDom.slideDown()
//   } else {
//     textDom.slideUp()
    

//   }
// })

$('.about-us-slide .swiper-wrapper').viewer({ url: "data-original" });
// // 图片懒加载
$(function() {
    $("img.lazyload").lazyload({effect: "fadeIn"});
});


//移动端表单显示隐藏

$('.contact-fixed').on('click', function (e) {
    $('.cl-introduction-right-close').show()
      $('body').addClass('fixed')
    $('.cl-introduction-right,.mask').show()
    // $('form').eq(0)[0].reset()
    // $('.upload-imgs').empty()

})
$('.cl-introduction-right-close').on('click', function (e) {
    $(this).toggle()
      $('body').toggleClass('fixed')
    $('.cl-introduction-right,.mask').toggle()
    $('form').eq(0)[0].reset()
    if ($('form').eq(1).length) { //新箱旧箱
        $('form').eq(1)[0].reset()
        
    }
    // $('form').eq(1)[0].reset()

    $('.upload-imgs').empty()

})
    /* 轮播图模块 */

    var galleryThumbs = new Swiper('.gallery-thumbs', {
            // 无限滚动
        // loop: true,
        // delay:4000,
        // autoplay:true,
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
    var galleryTop = new Swiper('.gallery-top', {
            // 无限滚动
        // loop: true,
        // delay:4000,
        // autoplay:true,
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next-rent',
            prevEl: '.swiper-button-prev-rent',
        },
        thumbs: {
            swiper: galleryThumbs
    }
    });




//  form 可见
var formView = $('.contact_form_wrap')[0]
var contactFiexd = $('.contact-us-fiexd')
$(document).on('scroll', function () {
    if (formView) {
        
        var eleShow =   isElementInViewport(formView)
        if (!eleShow) {
          contactFiexd.addClass('active')
        } else {
          contactFiexd.removeClass('active')
        }
    }
})

$('.contact-us-fiexd').on('click', function () {
  $("html,body").animate({ scrollTop:$($(this).data("id")).offset().top + "px"},300);
});



// 节流函数
function debounce(fn,delay) {
    var flag = true;
    return function (e){
        if(flag){
            setTimeout(() => {
                //到规定时间后执行函数，同时flag=true
                fn(this,arguments);
                flag = true;
            },delay);
        }
        //防止一直执行
        flag = false;
    };
}

// jq
$(window).on('scroll', debounce(function () {
    var slideHeight = $('.slider-box').outerHeight() || 0
    console.log('slideHeight :>> ', slideHeight);
    var top = $(document).scrollTop()
    if (top > slideHeight) {
        $('.navbar').addClass('blue')
    } else {
        $('.navbar').removeClass('blue')
        
    }
}, 200))
if ($('.tab-wrap').length) { 

    // $('.tab-wrap').sticky({topSpacing:$('.navbar').height()});
}

$('body').on('click', '.news-item', function (e) {
    var that = $(this)
    var type = that.data('type')
    
    localStorage.setItem('newsType',type)
})
/**
*Description:移动端弹窗收起导航栏
*Author:Name
*@Date:2022-09-21- 08:44:59
*/
$('body').on('click', '.about-us-li,.contact-fixed', function (e) {
    $('.navbar-toggler[aria-expanded="true"]').click()
})

// //监听图片加载失败
window.addEventListener('error', function (e) {
    let target = e.target, // 当前dom节点
        tagName = target.tagName,
        count = target.count || 0, // 以失败的次数，默认为0
        max = 3; // 总失败次数，此时设定为3
    var src = process.env.BASE_URL + 'images/error.webp'
    
    // 当前异常是由图片加载异常引起的
    if( tagName.toUpperCase() === 'IMG' ){
        if(count >= max){
            target.src = 'data:image/png;base64,UklGRloCAABXRUJQVlA4IE4CAAAQKACdASrKAKMAPm0uk0akIiGhKtNcoIANiWlu+F5mJouP5xb1wX9B9P7av//Rz1vQx330AFKQjP2/k+vz64nHhXo7jpRIBqdZqHmyRSCvWTy7RHieGY3WB+QmNlEvSiDkzg0H8gJnmT5XWLUyEliMKwKmORi4H+eg+BhTFhlgP0R8ciibGKOrQG0Bcfh4HZtELsaONGTs1DUhwZMc2Grwmrz+sIdmQubKbFgcFWSa5JGCW61cim4IzmV+SrnbjRVT8TLWvfHuTf4ZSfn1f/9RQR4ZNLLMMO6BZP+Iwy3/O1zRolHqtHYGJ8g1ZsSoqBfNBWO7fZIMc1Aly8J3SZU/v5iYlARRF7KdkZml1tdiA1DQOXcXmB/dXgVj+rP0w/J9XixPuMO5gAMbms9IG7M+5Z9DdlztURMWDZwpebZb0xPR1A+EV1X0QAD++yJJcZEApzHdKbdvTxEH1HqbcVbhEfr1FHgc6F3FAbya4qHavXy+m/tvK+cEn1w6AMqnzEwPRevxchmCka6aFEvpnMlKV7FM+OtDQU9LLyk646q0sxhw/DyD15t0ZwSLDJ7CB8nM+iggZRMKTWsKzc1hSx5KCzcEBBiTmzDeZ3Atk6qkuGuwx4Jn4A4EjMek1GDQFNMMSfJIexJ3a3ADNiFeRT3orYQIZ6qeti5Rpgs5c/5f7WehJJyurZ1R7yQLbkKLzhPktAoTe8ICPveymoVIAZCg35X0m3dJvLjNrPjY4j/ILZDba1XHYd1OyXye9gVxrRhK5LxPDMJj5AIPgAAAAA=='//base64图片字符串
        }else{
            target.count = count + 1
            target.src = src
        }
    }
},true)


const container = document.getElementById("searchList");

// 防抖
function debounce_search(_func_) {
    let run = true
    return function() {
        if (!run) return
        run = false
        setTimeout(() => {
        _func_.apply(this, arguments)
        run = true
        }, 300)
    }
}
function memorize(fn) {
    const cache = new Map()
    return (name) => {
        if (!name) {
        container.innerHTML = ""
            return
        }
        if (cache.get(name)) {
        container.innerHTML = cache.get(name)
            return
        }
        const res = fn.call(fn, name).join("")
        cache.set(name, res)
         
        container.innerHTML = res
    };
}
 
function handleInput(value) {
   const reg = new RegExp(`\(${value}\)`)
     const search = searchData.reduce((res, item) => {
         const { text, url } = item
         console.log('url :>> ', url)
     if (reg.test(text)) {
       const match = RegExp.$1
       res.push(`<li data-url="${url}" class="search-item">${text.replace(match, '<a  class=" search-link"><span class="match-text">$&</span></a>')}</li>`)
     }
     return res
   }, [])
   console.log('search :>> ', search)
   return search
}
var  searchData = []
const memorizeInput = memorize(handleInput)

function search(val) {
    var data = {
        content:val,
        language:'en',
    }
    data = JSON.stringify(data)

    // var url = BASE_API +   '/containerBusiness/harbour/search'
    var url = 'http://10.43.8.158:9000' +  '/containerBusiness/harbour/search'


    $.ajax({
        url:url,
        method: 'post',
        dataType: 'json',
        contentType: 'application/json',
        data:JSON.stringify(data),
        success: function (res) {
            if (res.data) {
                searchData = res.data 
                debounce_search(memorizeInput(val))
                $('.search-content-list.default').show()
                $('.search-content-list.suggest').hide()
            } else {
                $('.search-content-list.default').hide()
                $('.search-content-list.suggest').show()
                searchData =   []
                debounce_search(memorizeInput(''))
            }
            
        },
        fail: function (res) {
            $('.search-content-list.default').hide()
                $('.search-content-list.suggest').show()
                searchData =   []
                debounce_search(memorizeInput(''))
        },
        complete: function (res) {
            
        },
    })
}
//查询输入框输入事件
// $('body').on('input propertychange', '#searchIpt', function (e) {
//     var that = $(this)
//     var val = $.trim(that.val())
//     console.log('val :>> ', val);
//     search(val)
// })
// 点击查询图标,显示查询模块
$('body').on('click', '.search-dom', function (e) {
    var that = $(this)
    setTimeout(function () {
        
        $('.search-ipt').focus()
    },300)
    $('.navbar').addClass('search-show')

})
// 关闭查询模块
$('body').on('click', '.r-icon-box,.mask-search', function (e) {

    var that = $(this)
    $('#searchIpt').val('')
    $('.navbar').removeClass('search-show')

})
// 回车键进行查询
$('.search-ipt-box .search-ipt').keyup(function(event){
    // console.log(event.target.value);
    if(event.keyCode ==13){
        if (event.target.value) {
            location.href = './search.html'
            window.sessionStorage.setItem('searchValue',event.target.value)
        }else{
            $.message({
                message: 'Please enter search keywords',
            });
            return
        }
    }
});

//查询结果点击跳转
$('body').on('click', '.search-content-list.default .search-item', function (e) {

    var that = $(this)
    var url = that.data('url')
    location.href = url

})


 //导航栏二级菜单
$('body').on('mouseover', '.btn-dry-primary', function (e) {
    $('.reefer,.tank').removeClass('show')
    $('.dry').addClass('show')
    $('.tank').removeClass('show')
})
//导航栏二级菜单
$('body').on('mouseover', '.btn-reefer-primary', function (e) {
    $('.dry,.tank').removeClass('show')
    $('.reefer').addClass('show')
    $('.tank').removeClass('show')
})
//导航栏二级菜单
$('body').on('mouseover', '.btn-tank-primary', function (e) {
    $('.dry').removeClass('show')
    $('.reefer').removeClass('show')
    $('.tank').addClass('show')
})
//导航栏二级菜单
$('body').on('mouseover', '.btn-tank-primary', function (e) {
    $('.dry,.reefer').removeClass('show')
    $('.tank').addClass('show')
})
//导航栏二级菜单
$('body').on('mouseover', '.dropdown-toggle', function (e) {    
    $('.dry').removeClass('show')
    $('.reefer').removeClass('show')
})
//获取归属地
function getIP(val) {
    var url = BASE_API2 +  '/containerBusiness/news/getCurrentArea'
    $.ajax({
        url:url,
        method: 'get',
        dataType: 'json',
        contentType: 'application/json',
        timeout:3000,
        success: function (res) {
            console.log(res)
            if (res.data && (res.data === 'CN' || res.data === 'cn')) {
                location.href = BASE_API2 + '/'
            }
        },
        fail: function (res) {
            console.log(res)
        },
        complete: function (res) {
            console.log(res)
        },
    })
}
//备案信息动态设置
$(function () {
    // getIP()
    var beianNum = '',beianCompany =  '' //备案号,公司名称
    var origin = window.location.origin
    if (origin.indexOf('cimcshop') > -1) {
        beianNum = '粤ICP备13010776号-25'
        beianCompany = '深圳中集智能科技有限公司'
    } else if(origin.indexOf('ucontainers.com.cn') > -1){
        beianNum = '粤ICP备13010776号-26'
        beianCompany = '深圳中集智能科技有限公司'
    } else {
        beianNum = '粤ICP备17101910号-13'
        beianCompany = '深圳中集科技有限公司'
    }
     $('.beian-info-val').text(beianNum)
     $('.beian-info-company').text(beianCompany)
 })