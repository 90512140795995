//防抖
export function debounce(func, wait, immediate){
  var timeout, args, context, timestamp, result;
  if (null == wait) wait = 100;

  function later() {
    var last = Date.now() - timestamp;

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        context = args = null;
      }
    }
  };

  var debounced = function(){
    context = this;
    args = arguments;
    timestamp = Date.now();
    var callNow = immediate && !timeout;
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };

  debounced.clear = function() {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };
  
  debounced.flush = function() {
    if (timeout) {
      result = func.apply(context, args);
      context = args = null;
      
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return debounced;
};
// 节流
export function throttle(fun, delay = 500) {
  
  let timer = null;
  let prev = 0;
  return function(args,form) {
    let now = Date.now();
    let remaining = delay - (now-prev); // 距离规定时间,还剩多少时间
    let that = this;
    let _args = args;
    clearTimeout(timer);//清除之前设置的定时器
    if (remaining <= 0) {
      fun.call(that, _args,form);
      prev = Date.now();
    } else {
      timer = setTimeout(function() {
        fun.call(that, _args,form);
      }, remaining);//因为上面添加的clearTimeout.实际这个定时器只有最后一次才会执行
    }
  }
}
// 获取表单数据
export function getFormData(form) {
  
  let obj = {}
  let arr = $(form).serializeArray()
  if (arr.length) {
    arr.map(v => {
      obj[v.name] = v.value.trim()
    })
  }
  return obj
}
//封装jquery ajax
export function sendAjax(path, obj) {
  var url = BASE_API + path
  var deffered = $.Deferred()
  let lan = 'en'

  if(lan.toLowerCase().indexOf('zh')!==-1){
    obj['language'] = 'zh-cn'

  }else if(lan.toLowerCase().indexOf('en')!==-1){
    obj['language'] = 'en'

  }


  var data = JSON.stringify(obj)
  $.ajax(url, {
      type: 'post',
      dataType: 'json',
      contentType: 'application/json',
      data,
      async : true,
      beforeSend:function () {
      },
  })
    .done(function (res) {
      deffered.resolve(res);
        
    }).fail(function (err) {
       console.log('err :>> ', err);
      // Ajax 调用失败，向用户呈现消息，同时不需要进行后续的业务处理
      deffered.reject(err);
    }).always(function (always) {
      console.log('always :>> ', always);      
    })
  
  return deffered.promise();
}

export function addressList (){
  const arr = [
    {name:'东莞南方中集物流装备制造有限公司',city:'东莞'},
    {name:'宁波中集集装箱制造有限公司',city:'宁波'},
    {name:'宁波中集物流装备有限公司',city:'宁波'},
    {name:'青岛中集集装箱制造有限公司',city:'青岛'},
    {name:'上海中集宝伟工业有限公司',city:'上海'},
    {name:'上海中集洋山物流装备有限公司',city:'上海'},
    {name:'深圳南方中集东部物流装备制造有限公司',city:'深圳'},
    {name:'太仓中集集装箱制造有限公司',city:'苏州'},
    {name:'天津中集北洋集装箱有限公司',city:'天津'},
    {name:'天津中集集装箱有限公司',city:'天津'},
    {name:'漳州中集集装箱有限公司',city:'漳州'},
 ]
  return arr
}
export function validEmail (str){
  const reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
  return reg.test(str)
}
export function validPhone (str){
  const reg1 = /\d{3}-\d{8}|\d{4}-\d{7}/
  const reg2 = /^1((3[\d])|(4[5,6,9])|(5[0-3,5-9])|(6[5-7])|(7[0-8])|(8[1-3,5-8])|(9[1,8,9]))\d{8}$/
  return reg1.test(str) || reg2.test(str2)
}
 
//提示必须要联系方式
export const hasNameOrEmail = ({ mobile, email ,emailAndMobile }) => {
  
  if(!mobile && !email && !emailAndMobile) {
      $.message({
        message: 'Fill in at least one contact number and Email address!',
      });
      return false
  }
  return true
}

// 根据宽度设置input 尺寸
(function () {
  var width = $(window).width()

  console.log('width :>> ', width);
  var inputList= $('.form-control')
  , btnList = $('.btn')
  , rentInputList = $('.rent .form-control')
  , rentBtnList = $('.rent .btn')
  if (width < 1500 && width > 1400) {
    inputList.removeClass('form-control-lg')
    btnList.removeClass('btn-lg')
    $('.select2-selection .select2-selection__rendered').css({'font-size':'1rem'})


  } else if (width < 1400 && width > 768) {
        //租赁页面输入框较多 需要更小
        inputList.removeClass('form-control-lg')
        btnList.removeClass('btn-lg')
        rentInputList.addClass('form-control-sm')
    rentBtnList.addClass('btn-sm')
    $('.select2-selection .select2-selection__rendered').css({'font-size':'1rem'})
    
  } else {
    inputList.addClass('form-control-lg')
    btnList.addClass('btn-lg')
  }
  
  setTimeout(function () {
    var height = $('.form-control').eq(0).innerHeight()
    $('.select2-selection,.select2-selection__arrow').height(height)
    $('.select2-selection .select2-selection__rendered').css({'line-height':height + 'px','font-size':'1.25rem'})
  },0)
})()

// 图片懒加载
$(function() {
    // $("img.lazy").lazyload({effect: "slideDown"});
});

// 内容自适应高度

export function fitHeight(ele,width) {
  return !function () {
    var dom = $(ele)
        var maxHeight = 0,arr = []
    dom.each(function (i, v) {
          if (width) {
            arr.push($(v).outerWidth())
            
          } else {
            
            arr.push($(v).innerHeight())
          }
        })
        console.log('arr :>> ', arr);
        arr.forEach((item) => {
            if (item > maxHeight) {
            maxHeight = item;
            } 
        });
        if (width) {
          dom.css({width:maxHeight + 'px'})
            
        } else {
          
          dom.css({height:maxHeight + 'px'})
        }
    }()
}
//浏览器地址获取参数
export function getParams(key) {
  let search = window.location.search.replace(/^\?/, "");
  let pairs = search.split("&");
  let paramsMap = pairs.map(pair => {
      let [key, value] = pair.split("=");
      return [decodeURIComponent(key), decodeURIComponent(value)];
  }).reduce((res, [key, value]) => Object.assign(res, { [key]: value }), {});
  return paramsMap[key] || "";
}

  //获取元素是否在可视区域

export function isElementInViewport(ele){
  var {top, right, bottom, left} = ele.getBoundingClientRect()
  var w = window.innerWidth
  var h = window.innerHeight
  if(bottom < 0 || top > h){
      // y 轴方向
      return false
  }
  if(right < 0 || left > w){
      // x 轴方向
      return false
  }
  return true
}

