 

import { throttleAjax } from '@/utils/common.js'
// import { initMap } from '@/utils/map.js'
import { debounce,fitHeight } from '@/utils/utils.js'

import './index.scss';
var lang = 'en'
 
/* eslint-disable no-new */
var homeSlider =  new Swiper('#homeSwiper', {
    // 无限滚动
    loop: true,
    delay:4000,
    autoplay:true,
    // effect : 'fade',
    fadeEffect: {
      crossFade: true,
    },
    // 如果需要分页器
    pagination: {
        el: '.swiper-pagination-home',
        clickable: true,
    },
    // // 如果需要前进后退按钮
    navigation: {
        nextEl: '.swiper-button-next-home',
        prevEl: '.swiper-button-prev-home',
    },
    on: {
        slideChangeTransitionStart: function(){
            
            var activeIndex = this.activeIndex
          
            if (activeIndex === 3 || activeIndex === 0) {
                $('.head-btn-box,.top-wrap').hide()
                $('.nav-wrap').addClass('blue')
            } else {
                $('.head-btn-box,.top-wrap').show()
                $('.nav-wrap').removeClass('blue')


                
            }
            if ($(document).width() < 768) {
                console.log('activeIndex :>> ', activeIndex);
                $('.head-btn-box').addClass('active')
                $('.head-btn-box .col-sm-25').hide()

                if (activeIndex === 1 || activeIndex === 4) {
                    $('.head-btn-box .col-sm-25').eq(0).show()
                } else if (activeIndex === 2) {
                    $('.head-btn-box .col-sm-25').eq(1).show()
                    
                }
            }
        },
      },
    
});
    //鼠标覆盖停止自动切换
  homeSlider.el.onmouseover = function(){
    homeSlider.autoplay.stop();
  }
  
  //鼠标离开开始自动切换
homeSlider.el.onmouseout = function () {
    // 开发环境不需要自动
  
    homeSlider.autoplay.start();
  }


var saleSlide =  new Swiper('#saleSwiper', {
 
    // 无限滚动
    loop: true,
    delay:4000,
    autoplay:true,
   
    // 如果需要分页器
    pagination: {
        el: '.swiper-pagination-sale',
        clickable: true,
    },

    // // 如果需要前进后退按钮
    navigation: {
        nextEl: '.swiper-button-next-sale',
        prevEl: '.swiper-button-prev-sale',
    },
    lazy: true
});
var rentSlide = new Swiper('#rentSwiper', {
 
    // 无限滚动
    loop: true,
    delay:4000,
    autoplay:true,
   
    // 如果需要分页器
    pagination: {
        el: '.swiper-pagination-rent',
        clickable: true,
    },

    // // 如果需要前进后退按钮
    navigation: {
        nextEl: '.swiper-button-next-rent',
        prevEl: '.swiper-button-prev-rent',
    },
    lazy: true
});
saleSlide.el.onmouseover = function(){
    saleSlide.autoplay.stop();
  }
  
  //鼠标离开开始自动切换
  saleSlide.el.onmouseout = function(){
    saleSlide.autoplay.start();
  }
  rentSlide.el.onmouseover = function(){
    rentSlide.autoplay.stop();
  }
  
  //鼠标离开开始自动切换
  rentSlide.el.onmouseout = function(){
    rentSlide.autoplay.start();
  }
var aboutSlide = new Swiper('.about-us-slide .swiper-container', {
    watchSlidesProgress: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    loopedSlides: 5,
    autoplay: true,
    slidesOffsetBefore: 0,
    navigation: {
        nextEl: '.swiper-button-next-about-us',
        prevEl: '.swiper-button-prev-about-us',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable :true,
    },
    on: {
        progress: function (e) {
            for (var i = 0; i < this.slides.length; i++) {
                var s = this.slides.eq(i),
                    t = this.slides[i].progress,
                    modify,
                    translate,
                    opacity,
                    scale,
                    zIndex
                (modify = 1) < Math.abs(t) && (modify = .3 * (Math.abs(t) - 1) + 1), translate = t *
                    modify * 60 + "px", scale = 1 - Math.abs(t) / 6, zIndex = 999 - Math.abs(Math
                    .round(5 * t)), opacity = 1 - Math.abs(t) / 4, s.transform("translateX(" +
                    translate + ") scale(" + scale + ")"), s.css("zIndex", zIndex), s.css("opacity",
                    opacity), 3 < Math.abs(t) && s.css("opacity", 0)
            }
        },
        setTransition: function (e) {
            for (var s = 0; s < this.slides.length; s++) {
                this.slides.eq(s).transition(e)
            }
        }
    }
    

});
aboutSlide.el.onmouseover = function(){
    aboutSlide.autoplay.stop();
  }
  
  //鼠标离开开始自动切换
  aboutSlide.el.onmouseout = function(){
    aboutSlide.autoplay.start();
  }
// 提交表单 节流
var path = '/containerBusiness/leave-messge/insert'
throttleAjax(path)
// 英文下设置按钮自动撑开
    $('.head-btn-box .col-sm-25').addClass('en')

 
//渲染新闻模板
function htmlStr(list) {
    if (list.length > 6) {
         list = list.slice(0,6)
     }
    let html = ''
    list.map((v,i) => {
        html += `
        <div class="col-sm-4">
        <div class="item">
            <div class="img-box">
                <img class="lazy" src="${v.imgUrl}" alt="ZhongJi Intelligent">
            </div>
            <div class="news-content">
                <h3 class="news-content-title" title="${v.title}">${v.title}</h3>
                <time class="news-content-time">${v.date || ''}</time>
                <p class="news-content-text">${v.content || ''}</p>
                <div class="news-btn">
                    <a class="item-btn-circle min"  data-index="${i}" data-id="${v.id}"  data-type="${v.type}" data-href="${v.url}" target="_blank">View Detail</a>
                </div>
            </div>
        </div>
    </div>
        `
    })
    return html
}
// 获取新闻列表
var newsList = []
$(function () {
    if ($('.items .col-sm-4').length > 6) {
        
        $('.news-btn-wrap').addClass('active')
        $('.more-news').show()
    } else {
        $('.more-news').hide()
        
    }
    function getNews() {
        $('.news .items').html('')
        // const url = `https://ucontainers.com/containerBusiness/news/getList?language=${lang}&pageIndex=1&pageSize=6`
        // const url = `http://10.43.8.158:9000/containerBusiness/news/getList?language=${lang}&pageIndex=1&pageSize=6`
        const url = `${BASE_API}/containerBusiness/news/getList?language=${lang}&pageIndex=1&pageSize=10`
    
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            contentType: 'application/json',
            timeout:5000,
            success(res) {
                const { rows } = res
                if (res.errorCode === 200 && rows.length) {
                    newsList = rows
                    if (newsList.length > 6) {
                        $('.news-btn-wrap').addClass('active')
                        $('.news,.more-news').show()
                    }
                    let str = htmlStr(rows)
                    $('.news .items').html(str)
                    // $(".news img.lazy").lazyload({effect: "fadeIn",threshold:50});
                } else {
                    $('.news,.more-news').hide()
                }
            },
            error(err) {
                $('.news,more-news').hide()
    
                console.log('getNewserr :>> ', err);
            }
        });
    }
})

$(".hotBox").on('click', function () {
    console.log('object :>> ', $(this).data("id"));
    $("html,body").animate({ scrollTop:$($(this).data("id")).offset().top + "px"},300);
});
$('.news').on('click', '.item-btn-circle', function (e) {    
    var that = $(this)
    var href = that.data('href'),
        type = that.data('type'),
        index = that.data('index'),
        id = that.data('id'),
        name = that.data('name'),
        page = `./news/${name}.html`,

        curNews = newsList[index]
    
        localStorage.setItem('curNews', JSON.stringify(curNews))
    if (type == 1) {
        window.open(href);
    } else {
        window.open(page);
    }
});
$(function () {
    fitHeight('.btn-box-wrap .btn',true)
    // 设置智能设备高度一致
    !function () {
        if (lang==='en') {
            var dom = $('.hot-box .b-content')
            
        } else {
            
            var dom = $('.hot-box .b-content-main')
        }
        var maxHeight = 0,arr = []
        dom.each(function (i, v) {
          arr.push($(v).innerHeight())
        })
        console.log('arr :>> ', arr);
        arr.forEach((item) => {
            if (item > maxHeight) {
              maxHeight = item;
            } 
        });
        dom.css({height:maxHeight + 'px'})
    }()
    
    // 地图功能
    
    // initMap()
    // // 地图自适应窗口大小,防抖
    // window.onresize = debounce(initMap, 500)
    // // 刷新以及离开页面需要清除防抖定时器
    // $(window).on('beforeunload',function(){
    //     window.onresize.clear();
    // });
})

